// /js/pages/Home.js
import React from 'react';

function Home() {
  return (
    <div className="content">
      <h2>Welcome to the University of New England Cyber Security Club</h2>
      <p>Website currently under construction.</p>
    </div>
  );
}

export default Home;
