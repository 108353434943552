// /js/pages/Signup.js
import React from 'react';

function Signup() {
  return (
    <div className="content">
      <h2>Sign Up</h2>
      <p>Join UNE Cyber Security Club and be part of the community!</p>
    </div>
  );
}

export default Signup;
