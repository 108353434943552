import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; // Use HashRouter
import Navbar from './components/Navbar'; // Updated path for Navbar
import Home from './pages/Home'; // Updated path for Home
import Events from './pages/Events'; // Updated path for Events
import Signup from './pages/Signup'; // Updated path for Signup
import Contact from './pages/Contact'; // Updated path for Contact
import './styles.css';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/events" element={<Events />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root')); // Updated
root.render(<App />);
