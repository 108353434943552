// /js/pages/Contact.js
import React from 'react';

function Contact() {
  return (
    <div className="content">
      <h2>Contact Us</h2>
      <p>Get in touch with the UNE Cyber Security Club for more information!</p>
    </div>
  );
}

export default Contact;
